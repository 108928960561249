import * as React from "react";
import { observer } from "mobx-react";
import { withStores } from "stores";

import { UitkSpacing, UitkSpacingProps } from "@egds/react-core/spacing";

import {
  LodgingSearchForm as SearchForm,
  LodgingSearchFormInputs as SearchFormInputs,
  LodgingSearchFormOptions as SearchFormOptions,
} from "@shared-ui/retail-search-tools-product";
import { Viewport, ViewSmall, ViewExtraLarge, ViewMedium } from "@shared-ui/viewport-context";
import { ProductGalleryContext } from "@shared-ui/retail-product-gallery";

import { Destination, LodgingSearchFormProps } from "./typings";
import { useSignalSubscriber } from "@shared-ui/signal-events-context";
import { PropertyOffersSignalNames } from "@shared-ui/lodging-property-offers";
import { PropertyBookBarSignalNames } from "../PropertyBookBar/utils/constant";
import { useRefContext } from "@shared-ui/ref-context";
import { useOptimizeLodgingSearchLoadTimeExperiment } from "../../utility/useOptimizeLodgingSearchLoadTimeExperiment";
import { useSRPPrefetchAssetsExperiment } from "../../utility/useSRPPrefetchAssetsExperiment";
import { Field } from "@egds/react-core/form";
import { getFunnelLocation, getPageIdentity } from "src/utils/PageIdentityUtil";
import { useExperiment } from "@shared-ui/experiment-context";
import { PrefetchProductType } from "src/components/utility/usePrefetchAssets";

/**
 * Wrapper for `@shared-ui/retail-search-tools-product` SearchForm
 * Powered by https://flexmanager.prod.expedia.biz/app/legacy/moduleStore/show/378
 */
export const LodgingSearchForm = withStores(
  "context",
  "page"
)(
  observer((props: LodgingSearchFormProps) => {
    const { templateComponent, context, page } = props;
    const { registerTarget } = useRefContext();
    const { prefetchSRPAssets } = useSRPPrefetchAssetsExperiment(PrefetchProductType.LODGING_SRP);
    const { exposure: imageMosaicLargeScreensExposure } = useExperiment(
      "Adaptex_campaign_PDP_mosaic_for_large_screens"
    );
    const { exposure: imageMosaicMWebExposure } = useExperiment("Adaptex_campaign_PDP_mosaic_for_small_screens");
    const adaptexCampaignVariant = imageMosaicLargeScreensExposure.bucket !== 0 || imageMosaicMWebExposure.bucket !== 0;
    const { setAdaptExSuccessEvent } = React.useContext(ProductGalleryContext);

    const { metadata } = templateComponent;
    const { id } = metadata;
    const searchFormRef = registerTarget<HTMLDivElement>("lodgingSearchFormSection");
    /* istanbul ignore if */

    const {
      sticky,
      autoOpenCalendar,
      prefillDestination,
      prefillPropertyName,
      hideFlexibleDates,
      fieldToAutoSubmitOnChange,
    } = templateComponent.config;

    /* istanbul ignore next */
    const getLocationId = () => {
      if (context?.searchContext?.location) {
        return context.searchContext.location.id.toString();
      }
      return null;
    };

    const pageIdentity = getPageIdentity(page);

    const shouldSetPropertyId = getFunnelLocation(pageIdentity.funnelLocation) === "DETAILS";

    const destination: Destination = {};

    if (shouldSetPropertyId) {
      destination.pinnedPropertyId = getLocationId();
    } else {
      destination.regionId = getLocationId();
    }

    const prefillInputs = {
      propertySearchCriteriaInput: {
        primary: {
          destination: destination,
          rooms: [],
        },
      },
    };

    const isMobile = context?.deviceInformation?.mobile;
    const [toggleCalendar, setToggleCalendar] = React.useState(isMobile ? false : autoOpenCalendar ?? false);

    useSignalSubscriber({
      query: { type: PropertyOffersSignalNames.AVAILABILITY_CALL_TO_ACTION_CTA_BUTTON_CLICK },
      onSignal: () => setToggleCalendar(true),
    });

    useSignalSubscriber({
      query: { type: PropertyBookBarSignalNames.CHECK_AVAILABILITY_CALL_TO_ACTION_BUTTON_CLICK },
      onSignal: () => setToggleCalendar(true),
    });

    const { triggerPreloadingSearch } = useOptimizeLodgingSearchLoadTimeExperiment();
    const lodgingSearchExperimentProps = {
      includeFlexibleCalendarContent: hideFlexibleDates ?? true,
      includeFlexibleDatesContent: hideFlexibleDates ?? true,
    };

    const handleFieldChange = React.useCallback(
      (field: Field, event: React.SyntheticEvent, queryParams: string) => {
        triggerPreloadingSearch(field, event, queryParams);
        prefetchSRPAssets(field);
      },
      [prefetchSRPAssets, triggerPreloadingSearch]
    );

    const searchFormProps: SearchFormOptions & { inputs: SearchFormInputs } = {
      inputs: prefillDestination ? prefillInputs : {},
      onFieldChange: handleFieldChange,
      enableReflection: true,
      redirectToSearchResultOnSubmit: true,
      sticky,
      autoOpenCalendar,
      additionalAdapterFields: {
        historyDetail: true,
        xPageId: page?.pageId ?? "",
        popularFilter: true,
      },
      features: prefillPropertyName ? ["PREFILL_PROPERTY_NAME"] : null,
      showCalendar: toggleCalendar,
      toggleCalendar: setToggleCalendar,
      updateLatLongOnSelection: undefined,
      ...lodgingSearchExperimentProps,
      onSubmit: () => {
        if (adaptexCampaignVariant) {
          setAdaptExSuccessEvent("ADAPTEX_MOSAIC_VIEW_BOOKING_FORM_EVENT");
        }
      },
    };

    const searchFormMobileProps: SearchFormOptions & { inputs: SearchFormInputs } = {
      ...searchFormProps,
      autoSubmitOnFieldChangeConfig: {
        onDateFieldChange: fieldToAutoSubmitOnChange === "date-field",
      },
    };

    const mobileSpacing: UitkSpacingProps | null = {
      margin: {
        small: {
          inline: "three",
        },
      },
    };

    return (
      <>
        <section id={id} data-testid="lodging-search-form">
          <Viewport>
            <ViewSmall>
              <UitkSpacing {...mobileSpacing}>
                <div id="lodgingSearchFormSection" data-testid="lodgingSearchFormSection" ref={searchFormRef}>
                  <SearchForm {...searchFormMobileProps} />
                </div>
              </UitkSpacing>
            </ViewSmall>
            {sticky ? (
              <ViewMedium>
                <SearchForm {...searchFormProps} />
              </ViewMedium>
            ) : (
              <ViewMedium>
                <UitkSpacing margin={{ inline: "three" }}>
                  <div>
                    <SearchForm {...searchFormMobileProps} />
                  </div>
                </UitkSpacing>
              </ViewMedium>
            )}
            <ViewExtraLarge>
              <SearchForm {...searchFormProps} />
            </ViewExtraLarge>
          </Viewport>
        </section>
      </>
    );
  })
);

LodgingSearchForm.displayName = "LodgingSearchForm";

export default LodgingSearchForm;
